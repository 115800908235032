import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col, Card, CardBody, CardHeader, CardTitle } from 'reactstrap'

import { MegaTagTitle } from 'components/Elements'
import './custom.scss'

export const Privacy = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'privacy_policy'} />

      <Container>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle className={'pt-3'}>
                  <center>
                    <h1>Privacy Policy</h1>
                  </center>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <center>
                  <p>Last Updated: February 7, 2024</p>
                </center>

                <div className="prolusion">
                  <p>
                    <span>This Privacy Policy (the &ldquo;</span>
                    <strong>
                      <span>Privacy</span>
                    </strong>
                    <span>&nbsp;</span>
                    <strong>
                      <span>Policy</span>
                    </strong>
                    <span>&rdquo;) describes how Wayble Inc., dba Wayble (&ldquo;</span>
                    <strong>
                      <span>Wayble</span>
                    </strong>
                    <span>&rdquo;, &ldquo;</span>
                    <strong>
                      <span>us</span>
                    </strong>
                    <span>&rdquo;, &ldquo;</span>
                    <strong>
                      <span>we</span>
                    </strong>
                    <span>&rdquo;, or &ldquo;</span>
                    <strong>
                      <span>our</span>
                    </strong>
                    <span>
                      &rdquo;) handles your personal data. We are committed to protecting your
                      personal data and informing you about what we do with it.&nbsp;
                    </span>
                  </p>

                  <p style={{ textAlign: 'justify' }}>
                    <strong>
                      <span>
                        Please read this Privacy Policy before using any of our products or
                        services, or providing us with any of your personal data. By using any of
                        our products or services, or otherwise providing us with any of your
                        personal data, you consent to the handling of your personal data in
                        accordance with this Privacy Policy. If you do not agree with this Privacy
                        Policy, do not use our products or services, and do not otherwise provide us
                        with any personal data
                      </span>
                    </strong>
                    <span>.</span>
                  </p>
                </div>

                <ol>
                  <li>
                    <span>Overview of this Privacy Policy</span>

                    <ul>
                      <li>
                        <span>Definitions</span>
                      </li>
                      <li>
                        <span>Why We Collect Personal Data</span>
                      </li>
                      <li>
                        <span>What Personal Data We Collect</span>
                      </li>
                      <li>
                        <span>How We Collect Personal Data</span>
                      </li>
                      <li>
                        <span>Consent and Other Legal Grounds for Processing Personal Data</span>
                      </li>
                      <li>
                        <span>Disclosure of Personal Data</span>
                      </li>
                      <li>
                        <span>Retention of Personal Data</span>
                      </li>
                      <li>
                        <span>Security of Personal Data</span>
                      </li>
                      <li>
                        <span>Rights Relating to Personal Data</span>
                      </li>
                      <li>
                        <span>Third Party Policies</span>
                      </li>
                      <li>
                        <span>Communications with Us</span>
                      </li>
                      <li>
                        <span>Personal Data About Other Individuals</span>
                      </li>
                      <li>
                        <span>Children</span>
                      </li>
                      <li>
                        <span>Changes to Our Privacy Policy</span>
                      </li>
                      <li>
                        <span>Contact Us</span>
                      </li>
                      <li>
                        <span>Definitions</span>
                      </li>
                      <li>
                        <span>Why We Collect Personal Data</span>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <span> Definitions</span>

                    <div>
                      <p>
                        <strong>&ldquo;anonymized information&ldquo; </strong>
                        <span>
                          means information that has been anonymized or aggregated in such a way
                          that there is no serious possibility it can be used to identify an
                          individual.
                        </span>
                      </p>
                      <p>
                        <strong>&ldquo;contact information&ldquo; </strong>
                        <span>
                          means information to enable an individual at a place of business to be
                          contacted, such as the name, position name or title, business telephone
                          number, business address, business e-mail or business fax number of the
                          individual.
                        </span>
                      </p>
                      <p>
                        <strong>&ldquo;employer&ldquo; </strong>
                        <span> means an organization looking to hire talent.</span>
                      </p>
                      <p>
                        <strong>&ldquo;institution&ldquo; </strong>
                        <span> means a post-secondary institution or other organization.</span>
                      </p>
                      <p>
                        <strong>&ldquo;personal data&ldquo; </strong>
                        <span>
                          means information about an identifiable individual (e.g., name, mailing
                          address, e-mail address and phone number). Personal data does not include
                          contact information or anonymized information.
                        </span>
                      </p>
                      <p>
                        <strong>&ldquo;platform&ldquo; </strong>
                        <span>
                          means Wayble&rsquo;s online software as a service platform for matching
                          international students and graduates with internships and job
                          opportunities, connecting them with a supportive community, and providing
                          personalized resources for success.
                        </span>
                      </p>
                      <p>
                        <strong>
                          <span>&ldquo;process &ldquo; or &ldquo;processing&ldquo;</span>
                        </strong>
                        <span>
                          means any action that a person can take with respect to personal data,
                          including collecting, using, sharing, altering and storing personal data.
                        </span>
                      </p>
                      <p>
                        <strong>&ldquo;services&ldquo; </strong>
                        <span>
                          means Wayble&rsquo;s website, platform and any other products and services
                          provided by Wayble.
                        </span>
                      </p>
                      <p>
                        <strong>&ldquo;student &ldquo;</strong>
                        <span>
                          {' '}
                          means an international student, domestic student or an alumnus.
                        </span>
                      </p>
                      <p>
                        <strong>&ldquo;visitor&ldquo; </strong>
                        <span> means a person that visits our website or platform.</span>
                      </p>
                      <p>
                        <span>
                          For the purposes of this Privacy Policy: (a) words importing the singular
                          include the plural and vice versa, and words importing gender include all
                          genders; and (b) where the word &ldquo;including or &ldquo;includes is
                          used, it means &ldquo;including (or includes) without limitation
                        </span>
                      </p>
                    </div>
                  </li>

                  <li>
                    <span>Why We Collect Personal Data</span>

                    <div>
                      <p>
                        We may collect personal data to fulfill the following purposes (the
                        &ldquo;Purposes&ldquo;):
                      </p>

                      <div className="nested__ordered-list">
                        <ol type="a">
                          <li>to provide our services to you, including:</li>
                          <ol type="i">
                            <li>
                              to support your use of our services (e.g., opening and managing an
                              account on our platform);
                            </li>
                            <li>
                              to identify your preferences with respect to certain functionalities
                              and features of our platform;
                            </li>
                            <li>
                              to carry out our obligations arising from any agreements entered into
                              between you and us; and
                            </li>
                            <li>
                              <span>with respect to students: with respect to students:</span>
                              <ol type="A">
                                <li>
                                  to create candidate profiles accessible to subscribed employers on
                                  our platform’s database; and
                                </li>
                                <li>to facilitate recruitment efforts from employers;</li>
                              </ol>
                            </li>
                            <li>
                              <p>
                                with respect to employers and institutions, and to the extent that
                                the information collected is not considered contact information: to
                                create an account for employers and institutions and their
                                administrative users, as may be applicable;
                              </p>
                            </li>
                          </ol>
                          <li>
                            to respond to your communications with us (e.g., if you send us a
                            message through our platform);
                          </li>
                          <li>
                            to contact you about updates to the services and service-related
                            communications;
                          </li>
                          <li>for marketing and promotional offers for other services;</li>
                          <li>to collect opinions and comments in regard to our services;</li>
                          <li>
                            for internal statistical and operational purposes in order to better
                            understand how our services are used;
                          </li>
                          <li>
                            to improve our services, including with respect to security and
                            function; to improve our services, including with respect to security
                            and function;
                          </li>
                          <li>to investigate legal claims; and</li>
                          <li>
                            to carry out any other purpose which is disclosed to you and for which
                            you consent, or as is otherwise permitted by applicable law.
                          </li>
                        </ol>
                      </div>
                    </div>
                  </li>

                  <li>
                    <span>What Personal Data We Collect</span>

                    <div>
                      <p>Personal data that we may collect includes: </p>

                      <div className="nested__ordered-list">
                        <ol type="a">
                          <li>
                            <u>With respect to students: </u>first and last name, e-mail, phone
                            number, resume, and video recording of yourself. Students may also
                            optionally provide demographic information about themselves, such as
                            protected group identification, gender and ethnicity. Student personal
                            data is used to: (i) create a candidate profile on our platform, which
                            will be accessible to employers subscribed on the platform for
                            recruiting purposes; (ii) recommend job postings; and (iii) personalize
                            the students’ resource feed offered through the platform.
                          </li>
                          <li>
                            <u>With respect to employers: </u> to the extent that it is not
                            considered contact information, the first and last name, e-mail and
                            phone number of your contact person.
                          </li>
                          <li>
                            <u>With respect to institutions: </u> to the extent it is not considered
                            contact information, the first and last name, e-mail and phone number of
                            your contact person.
                          </li>
                          <li>
                            <u>
                              With respect to all visitors (including students, employers and
                              institutions):
                            </u>
                            <ol type="i">
                              <li>
                                information about how you use our services (e.g., your length of
                                visits to our platform, and the time and frequency of your visits to
                                our platform);{' '}
                              </li>
                              <li>
                                information about your computer or mobile device, such as your
                                Internet protocol (IP) address, device ID, browser and operating
                                system type, resolution of your screen, language settings in your
                                browser, referring URLs, and other technical information; and
                              </li>
                              <li>any other personal data provided to us. </li>
                            </ol>
                          </li>
                        </ol>
                        <p>
                          We do not collect credit card or debit card information. If you make an
                          online payment using such a payment card, your payment card information is
                          collected and processed by our online payment processing service provider.
                        </p>
                      </div>
                    </div>
                  </li>

                  <li>
                    <span>How We Collect Personal Data</span>
                    <p>
                      We may collect personal data from you and any devices that you use when you
                      access and use our services. This happens in two primary ways:
                    </p>

                    <div className="nested__ordered-list">
                      <ol type="a">
                        <li>when you submit information to us; and</li>

                        <li>
                          when we automatically collect information from your use of the services:
                          <ol type="i">
                            <li>
                              <p>
                                <u>Cookies</u>. We may use cookies and similar technologies to track
                                and analyze user activity in order to improve our services and
                                improve your experience. A cookie is a tiny element of data sent to
                                your browser from a website. The cookie is stored on your hard drive
                                so that your computer or other device may be recognized while you
                                interact with the website.
                              </p>
                              <p>
                                Most web browsers allow you to modify your settings to notify you
                                when you receive a cookie or to reject cookies entirely. Your
                                computer also provides you with the ability to clear all cookies
                                that have been stored onto your hard drive, should you wish to do
                                so. However, please be aware that you may be unable to access
                                certain parts of the services if you block or disable our cookies.
                                For more information about cookies, please see:{' '}
                                <a
                                  href="https://www.allaboutcookies.org/"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {' '}
                                  https://www.allaboutcookies.org/.
                                </a>
                              </p>
                            </li>
                            <li>
                              <span>
                                <u> Web Analytics</u>. We may use web analytics services (e.g.,
                                Google Analytics) in order to better understand the user experience
                                on our platform, including:
                              </span>

                              <ol type="A">
                                <li>how you reached our platform;</li>
                                <li>the website you came from;</li>
                                <li>how long you stayed on our platform;</li>
                                <li>what pages you looked at;</li>
                                <li>how many pages you looked at;</li>
                                <li>what buttons you clicked;</li>
                                <li>what browser you are using; and</li>
                                <li>what device you are using.</li>
                              </ol>

                              <div>
                                <p>
                                  Any use of analytics services by us may result in analytics
                                  providers collecting certain personal data when you access and use
                                  our platform. These third parties may set and access their own
                                  tracking technologies (including cookies and web beacons) and may
                                  otherwise collect or have access to your device identifier, site
                                  usage information, and related information about you.
                                </p>
                                <p>
                                  If you do not wish to participate in web analytics, you may be
                                  able to download an opt-out browser add-on. Your web browser may
                                  also allow you to set a &ldquo;Do Not Track&ldquo; signal when you
                                  use online services. To learn more about &ldquo;Do Not
                                  Track&ldquo; signals, visit{' '}
                                  <a
                                    href="http://www.allaboutdnt.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    http://www.allaboutdnt.com/.
                                  </a>{' '}
                                  You may also contact us at the &ldquo;Contact Us&ldquo; section
                                  below regarding any questions about web analytics services that we
                                  may use.
                                </p>
                              </div>
                            </li>
                          </ol>
                        </li>

                        <li>
                          <p>
                            <u>Third Party Data.</u> We may also obtain personal data from third
                            parties. We protect such personal data according to the practices
                            described in this Privacy Policy, plus any additional restrictions
                            imposed by the source of the data and applicable laws.
                          </p>
                        </li>

                        <li>
                          <p>
                            <u>Other Interactions. </u> We may also obtain personal data from you
                            when you otherwise interact with us online or offline, such as when you
                            communicate with us in person, by phone or by e-mail.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </li>

                  <li>
                    <span>Consent and Other Legal Grounds for Processing Personal Data</span>
                    <ol type="a">
                      <li>
                        <span>
                          <u>General</u> permitted or required to do so by applicable law. Your
                          consent may be express or implied. The type of consent we may rely on in
                          processing your personal data will vary based on your reasonable
                          expectations, the sensitivity of your personal data, and the circumstances
                          surrounding the collection of your personal data. Express consent is when
                          you willingly agree (e.g., orally, in writing, or electronic agreement) to
                          the processing of your personal data for particular purposes. Implied
                          consent is when: (i) you do not expressly give consent, but you rather
                          volunteer personal data for obvious purposes that a reasonable person
                          would consider appropriate in the circumstances; or (ii) you are given
                          notice and a reasonable opportunity to opt-out of your personal data being
                          processed for specified purposes, and you do not opt-out. General. We will
                          not process your personal data without your consent, unless we are
                          permitted or required to do so by applicable law. Your consent may be
                          express or implied. The type of consent we may rely on in processing your
                          personal data will vary based on your reasonable expectations, the
                          sensitivity of your personal data, and the circumstances surrounding the
                          collection of your personal data. Express consent is when you willingly
                          agree (e.g., orally, in writing, or electronic agreement) to the
                          processing of your personal data for particular purposes. Implied consent
                          is when: (i) you do not expressly give consent, but you rather volunteer
                          personal data for obvious purposes that a reasonable person would consider
                          appropriate in the circumstances; or (ii) you are given notice and a
                          reasonable opportunity to opt-out of your personal data being processed
                          for specified purposes, and you do not opt-out.{' '}
                        </span>
                      </li>

                      <li>
                        <span>
                          <u>European Union.</u> If the General Data Protection Regulation
                          (&ldquo;GDPR&ldquo;) is applicable to our services, we may process your
                          personal data on the following grounds, in addition to any other grounds
                          that may be available pursuant to applicable law:
                        </span>
                        <ol type="i">
                          <li>
                            <span>
                              {' '}
                              <u>Consent. </u>Where you have given us consent through a statement or
                              clear affirmative action agreeing to the processing of your personal
                              data. For instance: submitting your Personal Data on a form on our
                              platform for a specified purpose.
                            </span>
                          </li>

                          <li>
                            <span>
                              <u> Contract.</u> Where processing of personal data is necessary to
                              perform a contract to which you are a party with us, or which you have
                              agreed to enter with us.
                            </span>
                          </li>
                          <li>
                            <span>
                              <u>Legal Obligation.</u> To comply with a legal obligation to which we
                              are subject.
                            </span>
                          </li>
                          <li>
                            <span>
                              <u>Legitimate Interests.</u> Where the processing of personal data is
                              based on our legitimate interests, provided that these do not override
                              your own interests and fundamental rights. We have legitimate
                              interests in the following:
                            </span>
                            <ol type="A">
                              <li>to properly operate and deliver our services to you;</li>
                              <li>to understand how users engage with our services;</li>
                              <li>to improve our services;</li>
                              <li>to market our services; and</li>
                              <li>
                                to provide customer service, including responses to inquiries and
                                feedback.
                              </li>
                            </ol>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <span>
                          <u>Consent.</u> For certainty, by providing us with any of your personal
                          data, you consent to our handling of your personal data pursuant to this
                          Privacy Policy. Some of the personal data you choose to provide us may be
                          considered &ldquo;sensitive&ldquo; in some jurisdictions, such as
                          protected group identification, gender and ethnicity. By choosing to
                          provide this information to us, you consent to our handling of that
                          information as set out in this Privacy Policy.
                        </span>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <span>Disclosure of Personal Data</span>
                    <ol type="a">
                      <li>
                        <span>
                          <u>General</u> When we disclose your personal data to third parties, we
                          take reasonable measures to ensure that these third parties implement
                          appropriate technical and organizational measures in accordance with
                          applicable privacy and data protection laws. We minimize the amount of
                          personal data we disclose to what is directly relevant and necessary to
                          accomplish the specified purpose.
                        </span>
                      </li>
                      <li>
                        <span>
                          <u>Our Services.</u> As part of our platform’s functionality, we share
                          student candidate profiles with employers. Employers who are subscribed to
                          the platform are able to browse the candidate database on the platform and
                          invite them to make an application or to have an interview. Employers can
                          contact students directly via e-mail or phone.
                        </span>
                      </li>
                      <li>
                        <span>
                          <u>Service Providers.</u> We may provide your personal data to third party
                          service providers in order to help us achieve the Purposes. For example,
                          we may use third party service providers to help us: (i) process payments;
                          (ii) analyze usage of our services; (iii) track the effectiveness of our
                          marketing strategies and communications; (iv) assist with the prevention,
                          detection and investigation of potentially illegal acts and security
                          breaches; and (v) collect and process error and crash reports and store
                          data.
                        </span>
                      </li>
                      <li>
                        <span>
                          <u>Corporate Transactions. </u> We may also disclose or transfer your
                          personal data to affiliates or third parties in the event of a proposed
                          reorganization, merger, sale, joint venture, assignment, transfer or other
                          disposition of all or any portion of our business, assets or equity,
                          subject in each case to customary confidentiality agreements.
                        </span>
                      </li>
                      <li>
                        <span>
                          <u>Legal Disclosure.</u> We may disclose personal data if we reasonably
                          believe disclosure is in accordance with or required by any applicable
                          law. This includes the disclosure of personal data where necessary for the
                          establishment, exercise, or defense of legal claims, or as otherwise
                          permitted by applicable law. We will have no liability associated with any
                          such disclosures made in good faith.
                        </span>
                      </li>
                      <li>
                        <span>
                          <u>International Transfer and Storage.</u> Your personal data may be
                          stored and processed in any country where we have facilities or in which
                          we engage third party service providers. Currently, our platform is
                          supported by Amazon Web Services (AWS) in the Canada-Central region. By
                          using our services and otherwise providing us with your personal data, you
                          consent to the transfer of your personal data to countries outside of your
                          country of residence, which may have different data protection rules than
                          in your country. Such personal data will be subject to the laws of the
                          country in which it is held, and may be subject to disclosure to the
                          governments, courts or law enforcement or regulatory agencies of such
                          other country. If applicable, we will comply with the GDPR requirements
                          providing adequate protection for the transfer of personal data from the
                          European Economic Area to a third country.
                        </span>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <span>Retention of Personal Data</span>
                    <p>
                      We will retain personal data only as long as necessary to: (a) fulfill the
                      Purposes; (b) protect our legal rights; and (c) comply with applicable law. We
                      will securely delete or anonymize the personal data once it is no longer
                      necessary for us to hold it. We are not responsible for any liability or loss
                      you experience as a result of such disposal of personal data. For certainty,
                      anonymized information and statistical summaries (with all personal data
                      removed) are stored indefinitely.
                    </p>
                  </li>

                  <li>
                    <span>Security of Personal Data</span>
                    <p>
                      We protect your personal data using appropriate technical and organizational
                      measures to reduce the risks of loss, misuse, unauthorized access, disclosure
                      and alteration. These measures include the following:
                    </p>
                    <ol type="a">
                      <li>
                        Student, staff and employer information, other than images and video, are
                        stored in a MySQL database managed by the Relational Database Service (RDS)
                        provided by Amazon Web Services (AWS). This database instance, and all
                        backups and replicas, are encrypted using the AES-256 encryption. Access to
                        the database is encrypted using SSL/TLS with a SSL certificate installed
                        during database creation. Unencrypted access is disabled. All access to the
                        database instance is restricted via a VPN (Virtual Private Network) to
                        authorized servers. The database cannot be accessed via the Internet.
                      </li>
                      <li>
                        For any images, video, etc., that are uploaded to our platform, they are
                        stored on AWS S3 service and must, by their nature, be accessible via the
                        Internet.
                      </li>
                    </ol>
                    <p>
                      No method of storage or transmission of personal data is 100% secure.
                      Therefore, we cannot guarantee absolute security. We encourage you to also
                      take steps to protect personal data by, for example, closing all web browsers
                      after using the platform.
                    </p>
                  </li>
                  <li>
                    <span>Rights Relating to Personal Data</span>
                    <ol type="a">
                      <li>
                        <p>
                          <u>Genera. </u> You have certain rights relating to your personal data,
                          subject to applicable law in each instance:
                        </p>
                        <div className="nested__ordered-list">
                          <ol type="i">
                            <li>
                              <p>
                                <u>Right to be Informed.</u> To know how your personal data is
                                collected, how it is used, and how it is disclosed and stored. This
                                further includes:
                              </p>
                              <div className="nested__ordered-list">
                                <ol type="A">
                                  <li>
                                    <u>Right to Withdraw Consent.</u> To withdraw your consent to
                                    the processing of your personal data at any time. Please be
                                    advised that withdrawing your consent to the processing of
                                    personal data may impact the provision of the services. For
                                    further information on this, please contact us as provided in
                                    the &ldquo;Contact Us&ldquo; section below.
                                  </li>
                                  <li>
                                    <u>Right to Make a Complaint. </u> To make a complaint about the
                                    processing of your personal data.
                                  </li>
                                </ol>
                              </div>
                            </li>
                            <li>
                              <u>Right to Access. </u> To receive a copy of your personal data.
                            </li>
                            <li>
                              <u>Right to Rectification.</u> To request correction of your personal
                              data.
                            </li>
                          </ol>
                        </div>
                      </li>
                      <li>
                        <p>
                          <u>European Union. </u> If the GDPR is applicable to our services, and you
                          are an individual to whom the GDPR applies, then you have the following
                          additional rights relating to your personal data, subject to applicable
                          law in each instance:
                        </p>
                        <div className="nested__ordered-list">
                          <ol type="i">
                            <li>
                              <p>
                                <u>Right to Erasure. </u> To request the deletion of personal data
                                when: (A) you believe it is no longer necessary for the purposes
                                collected; (B) you withdraw your consent or otherwise object to the
                                processing of your personal data; or (C) your personal data was
                                unlawfully processed.
                              </p>
                            </li>
                            <li>
                              <p>
                                <u>Right to Restriction of Processing. </u> To restrict the
                                processing of personal data if you dispute its accuracy or object to
                                its processing.
                              </p>
                            </li>
                            <li>
                              <p>
                                <u> Right to Portability. </u> To request that your personal data be
                                transferred to another organization.
                              </p>
                            </li>

                            <li>
                              <p>
                                <u>Right to Information about Automated Decision Making. </u> To
                                receive information about the basis of any automatic decision making
                                (e.g., algorithms).
                              </p>
                            </li>
                            <li>
                              <p>
                                <u>Right not to be Subject to Automated Decision Making. </u> To not
                                be subject to decisions based solely on automated processing, unless
                                this is necessary pursuant to a contract between us.
                              </p>
                            </li>
                            <li>
                              <p>
                                <u>Right to Object to Processing Activities. </u> To object to the
                                processing of your personal data based on our legitimate interests.
                              </p>
                            </li>
                          </ol>
                        </div>
                        <p>
                          If you want to learn more about any rights under the GDPR, you can visit
                          the European Commission’s page on Data Protection at{' '}
                          <a
                            href="https://ec.europa.eu/info/law/law-topic/data-protection_en"
                            target="_blank"
                            rel="noreferrer"
                          >
                            https://ec.europa.eu/info/law/law-topic/data-protection_en
                          </a>
                          .
                        </p>
                      </li>
                      <li>
                        <p>
                          <u>Exercising Your Rights.</u> If you would like to exercise your any of
                          your personal data rights, please refer to our &ldquo;Contact Us&ldquo;
                          section below. When doing so, please tell us which right you wish to
                          exercise and provide us with contact information to direct our response.
                          Your use of the services may be affected depending on the nature of the
                          rights you seek to exercise.
                        </p>
                        <p>
                          Subject to applicable law: (i) we may verify your identity before
                          fulfilling requests to exercise any rights; and (ii) we may request
                          additional information if we cannot initially verify your identity, or if
                          we require such additional information in order to properly assess your
                          request. Any personal data that you disclose to us for the purposes of
                          verifying your identity and/or exercising your rights will solely be used
                          for those purposes.
                        </p>
                      </li>
                    </ol>
                  </li>

                  <li>
                    <span>Third Party Policies</span>
                    <p>
                      This Privacy Policy addresses only the handling of personal data that we
                      collect from you. If you disclose your personal data to others, or if you
                      engage any a third-party sites or services, such third-party’s privacy notices
                      and practices will apply.
                    </p>
                    <p>
                      We cannot guarantee the privacy or security of your personal data once you
                      provide it to a third party and we encourage you to evaluate the privacy and
                      security policies of each such third-party before any such engagement or
                      disclosure.
                    </p>
                    <p>
                      Our platform may contain links to third party websites, products or
                      applications that are not owned, maintained or operated by us. Any such links
                      are provided solely as a convenience to you and not as an endorsement by us.
                      Wayble is not responsible for the content of such linked third-party websites,
                      products or applications, and does not make any representations, warranties or
                      guarantees regarding the content or the privacy practices of such third
                      parties. For certainty, Wayble disclaims any liability associated with your
                      access to, use of, download of, or reliance on, such third-party websites,
                      products or applications and their content. If you decide to access, use or
                      download any such third-party website, product, application or content, you do
                      so at your sole risk.
                    </p>
                  </li>
                  <li>
                    <span>Communications with Us</span>
                    <p>
                      To opt-out of receiving certain communications from us (the
                      &ldquo;Communications&ldquo;), you may either contact us as provided in the
                      &ldquo;Contact Us&ldquo; section below, or, if applicable, click
                      &ldquo;Unsubscribe&ldquo; if such function is supported in the particular
                      Communication (e.g., e-mail). Please note that certain Communications may be
                      necessary for the proper functioning and use of the services, and opting out
                      of those Communications may prevent the proper use of the services.
                    </p>
                  </li>

                  <li>
                    <span>Personal Data About Other Individuals</span>
                    <p>
                      Before providing any personal data on behalf of another individual, you
                      confirm that you have received all necessary consents and authorizations
                      required from them under applicable law so that we may process their personal
                      data in accordance with this Privacy Policy.
                    </p>
                  </li>

                  <li>
                    <span>Children</span>
                    <p>
                      Our services are not intended for, nor does Wayble knowingly collect
                      information from, children under the age of sixteen (16). If we learn that we
                      have collected personal data from a child under sixteen (16), we will take
                      steps to delete the information as soon as possible. If you are aware of a
                      user under the age of sixteen (16) using our services, please contact us
                      immediately.
                    </p>
                  </li>

                  <li>
                    <span>Changes to Our Privacy Policy</span>
                    <p>
                      We may amend this Privacy Policy from time to time in our sole discretion, so
                      please review it periodically. Unless otherwise indicated by us, any changes
                      to this Privacy Policy will apply immediately upon posting it to our platform.
                      Your continued use of the services after any changes to the Privacy Policy
                      will be conclusively deemed as your acceptance of the Privacy Policy as
                      amended.
                    </p>
                  </li>

                  <li>
                    <span>Contact Us</span>
                    <p>
                      If you have any questions about this Privacy Policy, or wish to exercise any
                      applicable rights, please contact us:
                    </p>
                  </li>
                </ol>
                <div>
                  <div style={{ textAlign: 'center' }}>
                    <p> Attention: Privacy Officer</p>
                    Wayble Inc.
                    <p>51 Wolseley street, </p>
                    <p>Toronto, ON M5T 1A5, Canada </p>
                    <p>
                      <a href="mailto:pat@joinwayble.com">pat@joinwayble.com</a>{' '}
                    </p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

Privacy.propTypes = {}
