import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Anchor } from 'components/Elements'
import { SubBar } from './SubBar'
import { Logo } from './Logo'

export const TitleBlock = ({
  data = {},
  config = {
    withApplication: false,
    withCompany: true,
  },
  ...props
}) => {
  const { t } = useTranslation()

  const { withApplication, withCompany } = config

  const { id, title, employer_profile } = data

  const company_logo = employer_profile?.company_logo

  return (
    <div className={'d-flex flex-wrap'}>
      {company_logo && (
        <div>
          <Logo data={data} />
        </div>
      )}

      <div className={'d-flex flex-column justify-content-center'}>
        <div className={'d-flex flex-wrap'}>
          <h5 className={'mb-0'}>
            <Anchor to={`/jobs/${id}`} color={'dark'} className={'text-decoration-underline'}>
              {title}
            </Anchor>
          </h5>
        </div>

        <SubBar data={data} withApplication={withApplication} withCompany={withCompany} />
      </div>
    </div>
  )
}

TitleBlock.propTypes = {
  data: PropTypes.object,
}
