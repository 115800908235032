import { takeLatest, takeEvery, fork, put, all, call } from 'redux-saga/effects'

import i18n from 'i18n'
import { jobsAPI } from '../../../../api'
import { jobJobsSavedList } from './slice'
import { RequestUtils } from 'utils'
import { authLogoutPA } from 'features/auth/store'

function* getList({ payload: { params } }) {
  try {
    let requestParams = {
      includes: [
        'user',
        'employerProfile',
        'jobType',
        'profileExperienceYear',
        'locationCity',
        'locationProvince',
        'mySavedJob',
      ],
    }

    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response = yield call(jobsAPI.getJobs, requestParams)

    const { data } = response

    const {
      meta: { current_page, last_page },
    } = data

    if (current_page && last_page && current_page > last_page) {
      yield put(
        jobJobsSavedList.getList({
          params: {
            ...params,
            page: last_page,
          },
        })
      )
    } else {
      yield put(jobJobsSavedList.getListSuccess(data))
    }
  } catch (error) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(jobJobsSavedList.getListError(message))
  }
}

function* watchGetList() {
  yield takeLatest(jobJobsSavedList.getList, getList)
}

function* cleanState() {
  yield put(jobJobsSavedList.cleanState({}))
}

function* watchUserLogout() {
  yield takeEvery(authLogoutPA.success, cleanState)
}

export function* listSaga() {
  yield all([fork(watchGetList), fork(watchUserLogout)])
}
