import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Anchor, AvatarProvider } from 'components/Elements'

export const Logo = ({ data = {}, ...props }) => {
  const { t } = useTranslation()

  const { user, employer_profile } = data

  const company_logo = employer_profile?.company_logo

  return user?.id ? (
    <Anchor to={`/employers/${user?.id}`}>
      <AvatarProvider className={'me-2'} variant={'logo'} avatar={company_logo} size={'size_3'} />
    </Anchor>
  ) : (
    <AvatarProvider className={'me-2'} variant={'logo'} avatar={company_logo} size={'size_3'} />
  )
}

Logo.propTypes = {
  data: PropTypes.object,
}
