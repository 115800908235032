import React from 'react'
import { PageContent } from 'components/Layouts'

import { MegaTagTitle } from 'components/Elements'
import { Modal, Button } from 'components/Elements'
import { useNavigate } from 'react-router-dom'

export const MeetMentor = () => {
  const navigate = useNavigate()
  const handleCancel = () => {
    navigate('/jobs')
  }

  return (
    <>
      <MegaTagTitle title={'meet_mentors'} />
      <PageContent>
        <div className="blurry meet-mentors"></div>
        <Modal visible={true} centered onClose={handleCancel}>
          <p>
            Oops, this feature is just for students from partner schools. Want to get your school
            access to it?
          </p>
          <div className="mt-4 d-flex justify-content-end">
            <div className="button-items">
              <Button outline color="light" onClick={handleCancel} size={'md'}>
                {'No'}
              </Button>

              <Button onClick={handleCancel} size={'md'}>
                {'Yes'}
              </Button>
            </div>
          </div>
        </Modal>
      </PageContent>
      {/* <Container>
        <Row>
          <Col>
            <Card>
              <CardBody></CardBody>
            </Card>
          </Col>
        </Row>
      </Container> */}
    </>
  )
}

MeetMentor.propTypes = {}
