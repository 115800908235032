import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useUser } from 'features/user'
import s from './Menu.module.scss'
import { EXTERNAL_LINK } from 'consts/core'
import { NavItem } from './NavItem'
import { Icon } from './Icon'
import { InvitesBadge } from './Badges'

export const Menu = () => {
  const { t } = useTranslation()

  let location = useLocation()
  let pathname = location.pathname

  const { isMentor } = useUser()

  const candidateNavs = [
    {
      label: t('dashboard'),
      Icon: <Icon name={'love-letter'} />,
      rightSection: <InvitesBadge type={'mark'} />,
      menu: [
        {
          path: '/profile',
          label: t('my_profile'),
          key: 'profile',
        },
        {
          path: '/invites',
          label: t('invites'),
          key: 'invites',
          rightSection: <InvitesBadge />,
        },
      ],
    },
    {
      label: t('get_hired'),
      Icon: <Icon name={'briefcase'} />,
      menu: [
        {
          path: '/jobs',
          label: t('browse_jobs'),
          activeCondition: () => pathname === '/jobs' || pathname === '/jobs/recommended',
          key: 'jobs',
        },
        {
          path: '/jobs/recommended',
          label: t('recommended'),
          key: 'recommended_jobs',
          hidden: true,
        },
        {
          path: '/jobs/applied',
          label: t('applied'),
          key: 'applied_jobs',
        },
        {
          path: '/jobs/saved',
          label: t('saved'),
          key: 'saved_jobs',
        },
        {
          path: '/employers',
          label: t('discover_employers'),
          key: 'employers',
        },
      ],
    },
    {
      label: t('learn'),
      Icon: <Icon name={'electric-light-bulb'} />,
      menu: [
        {
          path: '/online-courses',
          label: t('online_courses'),
          // external: true,
          key: 'online_courses',
        },
        {
          path: EXTERNAL_LINK.RESOURCES,
          label: t('free_resources'),
          external: true,
          key: 'free_resources',
        },
      ],
    },
    {
      label: t('connect'),
      Icon: <Icon name={'handshake'} />,
      menu: [
        {
          path: EXTERNAL_LINK.WHATSAPP_COMMUNITY,
          label: t('community'),
          external: true,
          key: 'community',
        },
        {
          path: '/mentors',
          label: t('meet_mentors'),
          hidden: isMentor(),
          key: 'mentors',
        },
      ],
    },
    {
      label: t('immigrate'),
      Icon: <Icon name={'globe-showing-americas'} />,
      menu: [
        {
          path: '/immigrate/faq',
          label: t('faqs'),
          key: 'faqs',
        },
      ],
    },
  ]

  const activeData = useMemo(() => {
    for (let i = 0; i < candidateNavs.length; i++) {
      const parent = candidateNavs[i]
      const { menu } = parent

      for (let j = 0; j < menu.length; j++) {
        const nav = menu[j]
        const { path, activeCondition } = nav

        const condition = activeCondition
          ? activeCondition()
          : pathname === path || (pathname.startsWith(path) && pathname.charAt(path.length) === '/')

        if (condition) {
          return {
            parentIndex: i,
            nav,
          }
        }
      }
    }

    return {}
  }, [location])

  return (
    <>
      {candidateNavs.map((item, i) => (
        <NavItem
          key={i}
          item={item}
          active={activeData?.parentIndex === i}
          defaultOpened={activeData?.parentIndex === i}
        >
          {item.menu && !!item.menu.length && (
            <>
              {item.menu
                .filter(nav => !nav.hidden)
                .map((nav, j) => (
                  <NavItem key={j} item={nav} active={activeData?.nav?.key === nav?.key} />
                ))}
            </>
          )}
        </NavItem>
      ))}
    </>
  )
}
